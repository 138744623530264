<template>
  <!-- ロゴ -->
  <a href="/">
    <div class="logo_left_top1"></div>
  </a>
  <div class="logo_left_top2" id="logo2">
    MUKU design studio inc.
  </div>
  <div class="logo_left_bottom"></div>
  <div class="copyright" id="copyright">
    ©MUKU ALL RIGHTS RESERVED.
  </div>
</template>

<script>

export default {
  name: 'Logo'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@media (max-width: 768px){
  .logo_left_top1, .logo_left_top2  {
    visibility: hidden;
  }
  .logo_left_bottom, .copyright {
    left: 4%;
    position: absolute;
  }
}
</style>