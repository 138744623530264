<template>
  <div class="container is-max-desktop">
    <div class="columns">
      <!-- メニュー -->
      <Menu />

      <!-- コンテンツ -->
      <div class="column">
        <div class="mobileSpacer"></div>
        <!-- コンタクト -->
        <Contact />
      </div>
      <Logo />
    </div>
  </div>

</template>

<script>
import Logo from '@/components/Logo.vue'
import Menu from '@/components/Menu.vue'
import Contact from '@/components/Contact.vue'

export default {
  components: {
    Logo,
    Menu,
    Contact
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>