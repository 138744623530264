<template>
  <!-- コンタクト -->
  <template v-if="contactData && contactData.data">
    <div class="columns">
      <div class="column sub-title pdt50">CONTACT</div>
    </div>
    <div class="columns" v-if="false">
      <!-- googleマップは非表示 -->
      <div class="column">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.0878123519747!2d139.66420601561066!3d35.650208080201544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f3614e72fdb3%3A0x914038d0989c84d4!2z44CSMTU0LTAwMDQg5p2x5Lqs6YO95LiW55Sw6LC35Yy65aSq5a2Q5aCC77yV5LiB55uu77yT77yR4oiS77yS!5e0!3m2!1sja!2sjp!4v1618673055441!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
    <div class="columns contact-contents contact_footer">
      <div class="column" v-html="contactData.data.contents_ja + '<br />' + contactData.data.contents_en">
      </div>
    </div>
  </template>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Contact',
  data(){
      return {
          contactData:[]
      }
  },
  mounted(){
      this.getContactData()
  },
  methods:{
    async getContactData(){
      this.contactData = await axios.get(
        'https://sample1-muku.microcms.io/api/v1/contact',
        {
          headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
div.contact-contents a {
  color: #333;
  text-decoration: underline;
}
</style>
<style scoped>
/* google map部分 */
iframe{
  width: 100%;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.contact_footer {
  margin-bottom: 100px;
}
@media (max-width: 768px){
  .contact-contents {
    margin-left: 4%;
    margin-right: 4%;
    line-height: 28px;
  }
.contact_footer {
  margin-bottom: 0px;
}
}
</style>