<template>
<!-- プロファイル -->
  <template v-if="profileDataList && profileDataList.data&& profileDataList.data.contents">
    <div class="columns">
      <div class="column sub-title pdt50">PROFILE</div>
    </div>
    <template v-for="(profileData, index) in profileDataList.data.contents" :key="index">
      <div class="columns">
        <div class="column profile-contents" v-html="profileData.contents_ja + '<br />' + profileData.contents_en">
        </div>
        <div class="column is-one-fifth profile-picture">
          <img :src="profileData.pic_main.url" />
        </div>
      </div>
    </template>
  </template>
  <template v-if="aboutUsData && aboutUsData.data">
    <div class="columns">
      <div class="column sub-title pdt100">ABOUT US</div>
    </div>
    <div class="columns">
      <div class="column about_us" v-html="aboutUsData.data.contents_ja + '<br />' + aboutUsData.data.contents_en">
      </div>
    </div>
  </template>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Profile',
  data(){
      return {
          profileDataList:[],
          aboutUsData:[]
      }
  },
  mounted(){
      this.getProfileList(),
      this.getAboutUsData()
  },
  methods:{
    async getProfileList(){
      this.profileDataList = await axios.get(
        'https://sample1-muku.microcms.io/api/v1/profile?limit=100',
        {
          headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
        }
      )
    },
    async getAboutUsData(){
      this.aboutUsData = await axios.get(
        'https://sample1-muku.microcms.io/api/v1/about-us',
        {
          headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.profile-contents {
  font-size: 16px;
}
.profile-picture {
  margin-left: 40px;
  margin-right: 50px;
  width: 150px;
}
.about_us {
  margin-right: 50px;
}
@media (max-width: 768px){
  .profile-contents, .about_us {
    margin-left: 4%;
    margin-right: 4%;
    line-height: 28px;
  }
  .profile-picture {
    margin-top: 40px;
    margin-left: 4%;
    width: 250px;
  }
}
</style>