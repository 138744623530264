<template>
  <div class="container is-max-desktop">
    <div class="columns">
      <!-- メニュー -->
      <Menu />

      <!-- コンテンツ -->
      <div class="column">
        <div class="mobileSpacer"></div>
        <!-- プロジェクト -->
        <ProjectList />
        <div class="projectListFooterSpacer"></div>
      </div>
      <Logo />
    </div>
  </div>

</template>

<script>
import Logo from '@/components/Logo.vue'
import Menu from '@/components/Menu.vue'
import ProjectList from '@/components/ProjectList.vue'

export default {
  components: {
    Logo,
    Menu,
    ProjectList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.projectListFooterSpacer {
  height: 100px;
}
@media (max-width: 768px) {
  .projectListFooterSpacer {
    height: 0px;
  }
}
</style>