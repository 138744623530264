<template>
  <!-- プロジェクト -->
  <template v-if="projectDataList && projectDataList.data&& projectDataList.data.contents">
    <template v-for="(projectData, index) in projectDataList.data.contents" :key="index">
      <template v-if="projectData.url === $route.params.url">
        <div class="columns">
          <div class="column project-pic">
            <img :src="projectData.pic_main.url + '?w=2000'" />
          </div>
        </div>
        <template v-for="(n,index) in 20" :key="index">
          <div class="columns" v-if="projectData['pic_sub' + n]">
            <div class="column project-pic">
              <img :src="projectData['pic_sub' + n].url + '?w=2000'" />
            </div>
          </div>
        </template>
        <div class="columns">
          <div class="column project_name">
            {{projectData.project_name}}
          </div>
        </div>
        <div class="columns contents_ja">
          <div class="column" v-html="projectData.contents_ja"></div>
        </div>
        <div class="columns contents_en">
          <div class="column" v-html="projectData.contents_en"></div>
        </div>
        <div class="columns copy">
          <div class="column" v-html="projectData.copyright"></div>
        </div>
      </template>
    </template>
  </template>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ProjectList',
  data(){
      return {
          projectDataList:[]
      }
  },
  mounted(){
      this.getProjectList()
  },
  methods:{
    async getProjectList(){
        this.projectDataList = await axios.get(
          'https://sample1-muku.microcms.io/api/v1/project?limit=100',
          {
            headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
          }
        )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.columns {
  margin-bottom: 0px;
}
.project-pic {
  padding-right: 10px;
  margin-bottom: 10px;
  line-height: 0px;
}
.project_name {
  /*font-weight: bold;*/
  letter-spacing: 0.05em;
  font-size: 120%;
  margin-top: 50px;
  margin-bottom: 40px;
}
.contents_ja, .contents_en {
  margin-bottom: 28px;
  margin-right: 50px;
}
.copy {
  margin-bottom: 100px;
}
@media (max-width: 768px){
  .project_name, .contents_ja, .contents_en, .copy {
    margin-left: 4%;
    margin-right: 4%;
  }
  .project-pic {
    line-height: 0px;
    padding-right: 0px;
    margin-bottom: 10px;
  }
.copy {
  margin-bottom: 0px;
}
}
</style>