import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag-next';

createApp(App)
.use(VueGtag, {
    property: {id: 'G-THJW65LZ1C', router}
  })
.use(router).mount('#app')
