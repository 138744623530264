<template>
  <!-- プロジェクト -->
  <template v-if="projectDataList && projectDataList.data && projectDataList.data.contents">
    <div class="columns">
      <div class="column sub-title pdt50">PROJECT</div>
    </div>
    <div class="columns" v-for="(project2DataList, index) in arrange(projectDataList.data.contents)" :key="index">
      <div class="column project-pic" v-if="project2DataList[0]">
        <router-link :to="{ path: '/project/' + project2DataList[0].url }">
          <div class="overlay">
            <img :src="project2DataList[0].pic_main.url + '?w=1000'" />
            <div class="mask" ontouchstart="">
            </div>
            <div class="caption" ontouchstart="">{{project2DataList[0].project_name}}</div>
          </div>
        </router-link>
      </div>
      <div class="column project-pic" v-if="project2DataList[1]">
        <router-link :to="{ path: '/project/' + project2DataList[1].url }" v-if="project2DataList[1].url">
          <div class="overlay">
            <img :src="project2DataList[1].pic_main.url + '?w=1000'" />
            <div class="mask" ontouchstart="">
            </div>
            <div class="caption" ontouchstart="">{{project2DataList[1].project_name}}</div>
          </div>
        </router-link>
      </div>
    </div>
  </template>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ProjectList',
  data(){
      return {
          projectDataList:[]
      }
  },
  mounted(){
      this.getProjectList()
  },
  methods:{
      async getProjectList(){
          this.projectDataList = await axios.get(
            'https://sample1-muku.microcms.io/api/v1/project?limit=100',
            {
              headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
            }
          )
      },
      arrange(list)  {
      let dist = [[]];

      if (list) {
        if (list.length % 2 != 0) {
          list.push([]);
        }
        list.forEach((el) => {
          let tail = dist.length - 1;

          dist[tail].push(el);

          if (dist[tail].length > 2 - 1)
            dist.push([]);
        });
      }
      return dist;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.columns:not(:last-child) {
    margin-bottom: 10px;
}
.project-pic {
  margin-right: 10px;
  line-height: 0px;
}
.overlay {
	overflow:		hidden;
	position:		relative;	/* 相対位置指定 */
  line-height: 0px;
}
/*.overlay img {
  max-width: 200%;
  width: 100%;
}*/
.overlay .caption {
  width: 100%;
  height: 100%;
	position:		absolute;	/* 絶対位置指定 */
	top:			0;
	left:			0;
	opacity:		0;	/* マスクを表示しない */
	text-align: 		left;
	padding-top:		20px;
	padding-left:		20px;
  color:			#fff;
  line-height: 28px;
	-webkit-transition:	all 0.2s ease;
  transition:		all 0.2s ease;
}
.overlay .mask {
	width:			100%;
	height:			100%;
  line-height: 0px;
	position:		absolute;	/* 絶対位置指定 */
	top:			0;
	left:			0;
	opacity:		0;	/* マスクを表示しない */
	background-color:	rgba(0,0,0,0.4);	/* マスクは半透明 */
	-webkit-transition:	all 0.2s ease;
  transition:		all 0.2s ease;
  transform: translate(-100%,-100%);
}
.overlay:hover .mask {
	opacity:		1;	/* マスクを表示する */
  transform: translate(0%,0%);
}
.overlay:hover .caption {
	opacity:		1;
}

@media (max-width: 768px){
  .project-pic {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .columns:not(:last-child) {
      margin-bottom: 0px;
  }
}
</style>