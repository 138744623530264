<template>
  <!-- プロジェクト -->
  <div class="columns">
    <div class="column sub-title news-title pdt50">NEWS</div>
  </div>
  <template v-if="newsDataList && newsDataList.data&& newsDataList.data.contents">
    <hr class="news-top" />
    <template v-for="(newsData, index) in newsDataList.data.contents" :key="index">
      <div class="columns news">
        <div class="column news-date">
          {{moment(newsData.update_date) }}
        </div>
        <div class="column news-contents" v-html="newsData.contents_ja"></div>
      </div>
      <div class="columns" v-if="newsData.pic_main">
        <div class="column news-contents">
          <img :src="newsData.pic_main.url" />
        </div>
      </div>
      <hr class="news-contents" />
    </template>
  </template>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'NewsList',
  data(){
      return {
          newsDataList:[]
      }
  },
  mounted(){
      this.getNewsList()
  },
  methods:{
    async getNewsList(){
      this.newsDataList = await axios.get(
        'https://sample1-muku.microcms.io/api/v1/news?limit=100',
        {
          headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
        }
      )
    },
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
div.news-contents a {
  color: #333;
  text-decoration: underline;
}
</style>
<style scoped>
.news-title {
  display: none;
}
hr {
  height: 1px;
  background-color: #ccc;
  margin: 30px 0;
}
hr.news-top {
  margin-top: 50px;
  margin-right: 50px;
}
.news-contents {
  margin-right: 50px;
}
.news-date {
  max-width: 120px;
}
@media (max-width: 768px){
  .columns.news {
    display: flex;
  }
  .news-title {
    display: block;
  }
  hr.news-top  {
    margin-top: 0px;
    margin-left: 4%;
    margin-right: 4%;
  }
  hr.news-contents  {
    margin-left: 4%;
    margin-right: 4%;
  }
   .news-date {
    margin-left: 4%;
   }
   .news-contents {
    margin-right: 4%;
  }
}
</style>