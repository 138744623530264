<template>
  <router-view />
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
@import "<%= BASE_URL %>/assets/css/bulma.min.css";

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
}

.v-enter-active, .v-leave-active {
  transition: opacity 1.5s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}
</style>
