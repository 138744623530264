<template>
  <!-- メニュー -->
  <div id="menu" class="column is-one-quarter m-sticky">
    <div class="navBar" id="mainNavBar" v-bind:class="{active:isShowNavBar && isTop && !isInit, inactive:!isShowNavBar && isTop && !isInit, inactiveImmediately:isTop && isInit, height70: !isMenuOpen}">
        
      <div id="menu_top">
        <img id="menu_logo" src="@/assets/img/muku_black.png" @click="goTop" />
        <div id="menu_copy">MUKU design studio inc.</div>
        <div class="btn-trigger" v-bind:class="{active:isMenuOpen, inactive:!isMenuOpen}" id="hamburger" @click="isMenuOpen=!isMenuOpen">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div id="menu_others" v-bind:class="{active:isMenuOpen, inactive:!isMenuOpen}">
        <router-link :to="{ path: '/news'    }" id="menu_news"><span>NEWS</span></router-link>
        <router-link :to="{ path: '/project' }" id="menu_project"><span>PROJECT</span></router-link>
        <router-link :to="{ path: '/profile' }" id="menu_profile"><span>PROFILE</span></router-link>
        <router-link :to="{ path: '/contact' }" id="menu_contact"><span>CONTACT</span></router-link>
      </div>
    </div>
      <!--&#9776; is the code for the 3 line menu button-->
  </div>

</template>

<script>

export default {
  name: 'Menu',
  props:{
    isTop:{
        type: Boolean,
        required: false,
        default:false
    }
  },
  data(){
      return {
          selectedMenuId:'',
          isMenuOpen:false,
          isShowNavBar:!this.isTop,
          isInit:this.isTop
      }
  },
  mounted(){
      this.selectMenu();
      window.addEventListener('scroll', this.showScroll);
      window.addEventListener('resize', this.showScroll);
  },
  methods:{
    selectMenu() {
      if (this.$route.path.split("/").length >= 2 && document.getElementById("menu_" + this.$route.path.split("/")[1]) != null) {
        document.getElementById("menu_" + this.$route.path.split("/")[1]).classList.add('underline');
      }
    },
    showScroll() {
      this.isInit = false;
      let isMobile = window.innerWidth <= 768;
      if (isMobile && this.isTop) {
          if (window.scrollY > 50) {
          this.isShowNavBar = true;
        } else {
          this.isShowNavBar = false;
          this.isMenuOpen = false;
        }
      }
    },
    goTop() {
      if (this.isTop) {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      } else {
        location.href='/';
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#mainNavBar a.underline span {
  border-bottom: 1px solid black;
  padding-bottom: 3px;
}
@media (min-width: 769px){
  #mainNavBar a:not(.underline) span:after {
    content: '';
      position: relative;
    width: 50%;
      display: inline-block;
  }
  #mainNavBar a:not(.underline):hover span:after {
    content: '';
      position: relative;
      bottom: -8px;
      display: inline-block;
      border-bottom: 1px solid black;
      -webkit-animation: menu-select .3s forwards;
      animation: menu-select .3s forwards;
    }
  @-webkit-keyframes menu-select {
    0% {
      -webkit-transform: translateX(-300%);
    }
    100% {
      -webkit-transform: translateX(-100%);
      width: 50%;
    }
  }
  @keyframes menu-select {
    0% {
      transform: translateX(-200%);
    }
    100% {
      transform: translateX(-100%);
      width: 50%;
    }
  }
}
#menu_top {
  display: none;
}
#menu {
  width: 300px;
}
.navBar{
  width: 220px;
  font-family: Century Gothic, 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  overflow: hidden;
  position: fixed;
  top: 162px;
  left: 50px;
}

/*Floats each item to the left with padding of 14 & 16 px.
Removes the underline with text decoration = none.*/
.navBar a{
  float: left;
  color: black;
  line-height: 50px;
  text-decoration: none;
  font-size: 17px;
  /*font-family: Tahoma;*/
  width: 100%;
}

/*Hides the menu Icon which will show when the nav needs to be responsive*/
.navBar .icon{
  display: none;
}

/*Set your custom screen width here replacing 700*/
@media (max-width: 768px){
  .navBar{
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 300;
  }
  .navBar div#menu_top {
    display: block;
    position: relative;
    height: 97px;
    padding-left: 0px;
    height: 70px;
    padding-top: 0px;
    border-bottom: 1px solid #333;
    z-index: 320;
    background-color: white;
  }
  .navBar a {
    padding-left: 4%;
    line-height: 17px;
    height: 97px;
    border-bottom: 1px solid #ccc;
    padding-top: 40px;
    z-index: 310;
    background-color: white;
  }
  .navBar a:last-child {
    border-bottom: 1px solid #333;
  }
  .navBar.height70 {
    height: 70px;
  }
  img#menu_logo {
    position: absolute;
    top: 26px;
    left: 4%;
    width: 63px;
    z-index: 400;
    cursor: pointer;
  }
  div#menu_copy {
    position: absolute;
    width: 100%;
    padding-top: 21px;
    text-align: center;
    font-size: 10px;
  }
  /* ハンバーガー */
  /*=============================
  .btn-trigger
  =============================*/
  .btn-trigger {
    position: absolute;
    top: 24px;
    right: 4%;
    width: 28px;
    height: 20px;
    cursor: pointer;
  }
  .btn-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333;
    border-radius: 2px;
  }
  .btn-trigger, .btn-trigger span {
    display: inline-block;
    transition: all .5s;
    box-sizing: border-box;
  }
  .btn-trigger span:nth-of-type(1) {
    top: 2px;
  }
  .btn-trigger span:nth-of-type(2) {
    top: 10px;
  }
  .btn-trigger span:nth-of-type(3) {
    bottom: 0;
  }
  #menu_others {
    position: relative;
  }
  /*=============================
  #hamburger
  =============================*/
  #hamburger span:nth-of-type(1) {
    -webkit-animation: hamburger-bar01 .75s forwards;
    animation: hamburger-bar01 .75s forwards;
  }
  @-webkit-keyframes hamburger-bar01 {
    0% {
      -webkit-transform: translateY(15px) rotate(45deg);
    }
    50% {
      -webkit-transform: translateY(15px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }
  @keyframes hamburger-bar01 {
    0% {
      transform: translateY(15px) rotate(45deg);
    }
    50% {
      transform: translateY(15px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  #hamburger span:nth-of-type(2) {
    transition: all .25s .25s;
    opacity: 1;
  }
  #hamburger span:nth-of-type(3) {
    -webkit-animation: hamburger-bar03 .75s forwards;
    animation: hamburger-bar03 .75s forwards;
  }
  @-webkit-keyframes hamburger-bar03 {
    0% {
      -webkit-transform: translateY(-15px) rotate(-45deg);
    }
    50% {
      -webkit-transform: translateY(-15px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }
  @keyframes hamburger-bar03 {
    0% {
      transform: translateY(-15px) rotate(-45deg);
    }
    50% {
      transform: translateY(-15px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  #hamburger.active span:nth-of-type(1) {
    -webkit-animation: active-hamburger-bar01 .75s forwards;
    animation: active-hamburger-bar01 .75s forwards;
  }
  @-webkit-keyframes active-hamburger-bar01 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(10px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(10px) rotate(45deg);
    }
  }
  @keyframes active-hamburger-bar01 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(10px) rotate(0);
    }
    100% {
      transform: translateY(10px) rotate(45deg);
    }
  }
  #hamburger.active span:nth-of-type(2) {
    opacity: 0;
  }
  #hamburger.active span:nth-of-type(3) {
    -webkit-animation: active-hamburger-bar03 .75s forwards;
    animation: active-hamburger-bar03 .75s forwards;
  }
  @-webkit-keyframes active-hamburger-bar03 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(-7px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(-7px) rotate(-45deg);
    }
  }
  @keyframes active-hamburger-bar03 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-7px) rotate(0);
    }
    100% {
      transform: translateY(-7px) rotate(-45deg);
    }
  }
  .navBar.active {
    -webkit-animation: navBar-active .75s forwards;
    animation: navBar-active .75s forwards;
  }
  @-webkit-keyframes navBar-active {
    0% {
      -webkit-transform: translateY(-70px);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }
  @keyframes navBar-active {
    0% {
      transform: translateY(-70px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .navBar.inactive {
    -webkit-animation: navBar-inactive .75s forwards;
    animation: navBar-inactive .75s forwards;
  }
  @-webkit-keyframes navBar-inactive {
    0% {
      -webkit-transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-70px);
    }
  }
  @keyframes navBar-inactive {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-70px);
    }
  }
  .navBar.inactiveImmediately {
    -webkit-animation: navBar-inactive .0s forwards;
    animation: navBar-inactive .0s forwards;
  }
  #menu_others.active {
    -webkit-animation: menu_others-active .75s forwards;
    animation: menu_others-active .75s forwards;
  }
  @-webkit-keyframes menu_others-active {
    0% {
      -webkit-transform: translateY(-500px);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }
  @keyframes menu_others-active {
    0% {
      transform: translateY(-500px);
    }
    100% {
      transform: translateY(0);
    }
  }
  #menu_others.inactive {
    -webkit-animation: menu_others-inactive .0s forwards;
    animation: menu_others-inactive .0s forwards;
  }
  @-webkit-keyframes menu_others-inactive {
    0% {
      -webkit-transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-500px);
      position: absolute;
      display: none;
    }
  }
  @keyframes menu_others-inactive {
    0% {
      transform: translateY(0) ;
    }
    100% {
      transform: translateY(-500px);
      position: absolute;
      display: none;
    }
  }


}

  </style>