<template>
  <!-- Primary Page Layout
  –––––––––––––––––––––––––––––––––––––––––––––––––– -->
  <div class="logo_left_top1 fade-in" id="logo1" @click="scrollForTop">
    <div class="white" id="logo1_white"></div>
  </div>
  <div class="logo_left_top2 fade-in top" id="logo2">
    MUKU design studio inc.
  </div>
  <div class="logo_left_bottom fade-in" id="logo_bottom">
    <div class="white" id="logo_bottom_white"></div>
  </div>
  <div class="copyright fade-in top" id="copyright">
    ©MUKU ALL RIGHTS RESERVED.
  </div>
  <div class="scroll fade-in-out" @click="scrollForContents">
    <img src="@/assets/img/arrow-down.png" style="width: 50px;" /> scroll
  </div>
  <!-- クロスフェードTOP -->
  <div class="box" v-if="topPicList && topPicList.data && topPicList.data.contents">
      <div :class="'bgImg src2'" :style="'background-image : url(' + topPicList.data.contents[2].pic.url + '?w=2000)'" ></div>
      <div :class="'bgImg src1'" :style="'background-image : url(' + topPicList.data.contents[1].pic.url + '?w=2000)'" ></div>
      <div :class="'bgImg src0'" :style="'background-image : url(' + topPicList.data.contents[0].pic.url + '?w=2000)'" ></div>
  </div>
  <div id="contents" class="container is-max-desktop">
    <div class="columns">
      <!-- メニュー -->
      <Menu :isTop="true" />

      <!-- コンテンツ -->
      <div class="column">

        <!-- プロジェクト -->
        <ProjectList />

        <!-- プロファイル -->
        <div class="spacer"></div>
        <Profile />
        
        <!-- コンタクト -->
        <div class="spacer"></div>
        <Contact />
        <div class="logo_left_bottom2"></div>
        <div class="copyright2">
          ©MUKU ALL RIGHTS RESERVED.
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import Menu from '@/components/Menu.vue'
import ProjectList from '@/components/ProjectList.vue'
import Profile from '@/components/Profile.vue'
import Contact from '@/components/Contact.vue'

export default {
  name: 'Top',
  components: {
    Menu,
    ProjectList,
    Profile,
    Contact
  },
  data(){
      return {
          projectDataList:[],
          topPicList:[]
      }
  },
  mounted(){
      this.getProjectList(),
      this.getTopPicList(),
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleScroll);
      this.handleScroll();
      let vw = window.innerWidth;
      window.addEventListener('resize', () => {
        if (vw === window.innerWidth) {
          return;
        }
        vw = window.innerWidth;
        this.setFillHeight();
      });
      this.setFillHeight();
  },
  methods:{
    async getProjectList(){
        this.projectDataList = await axios.get(
          'https://sample1-muku.microcms.io/api/v1/project?limit=100',
          {
            headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
          }
        )
    },
    async getTopPicList(){
        this.topPicList = await axios.get(
          'https://sample1-muku.microcms.io/api/v1/top-pics?limit=100',
          {
            headers: { 'X-API-KEY': 'a29e276c-615e-44f4-83e4-0f1004b84210' }
          }
        )
    },
    handleScroll() {
      //console.log("window.scrollY:" + window.scrollY + ",window.innerHeight:" + window.innerHeight);

      if (document.getElementById("logo1_white") == null) return;
      let isMobile = window.innerWidth <= 768;
      //console.log(isMobile);
      // logo-top1
      if (window.scrollY > window.innerHeight - 50) {
        document.getElementById('logo1_white').style.height = '0px';
        if (isMobile) {
          document.getElementById('logo1').style.height = '0px';
        }
      } else if (window.scrollY < window.innerHeight - 80) {
        document.getElementById('logo1_white').style.height = '30px';
        if (isMobile) {
          document.getElementById('logo1').style.height = '30px';
        }
      } else {
        document.getElementById('logo1_white').style.height = (window.innerHeight - window.scrollY - 50) + 'px';
        if (isMobile) {
          document.getElementById('logo1').style.height = (window.innerHeight - window.scrollY - 50) + 'px';
        }
      }

      // logo-top2
      if (window.scrollY > window.innerHeight - 100) {
        document.getElementById('logo2').style.color = 'black';
        if (isMobile) {
          document.getElementById('logo2').style.visibility = 'hidden';
        }
      } else {
        document.getElementById('logo2').style.color = 'white';
        if (isMobile) {
          document.getElementById('logo2').style.visibility = 'visible';
        }
      }

      // logo-bottom
      if (!isMobile) {
        if (window.scrollY > 150) {
          document.getElementById('logo_bottom_white').style.height = '0px';
          if (isMobile) {
            document.getElementById('logo_bottom').style.height = '0px';
            document.getElementById('logo_bottom').style.bottom = '150px';
          }
        } else if (window.scrollY < 50) {
          document.getElementById('logo_bottom_white').style.height = '100px';
          if (isMobile) {
            document.getElementById('logo_bottom').style.height = '100px';
            document.getElementById('logo_bottom').style.bottom = '50px';
          }
        } else {
          document.getElementById('logo_bottom_white').style.height = (150 - window.scrollY) + 'px';
          if (isMobile) {
            document.getElementById('logo_bottom').style.height = (150 - window.scrollY) + 'px';
            document.getElementById('logo_bottom').style.bottom = (window.scrollY) + 'px';
          }
        }
      } else {
        document.getElementById('logo_bottom_white').style.height = '100px';
      }

      // copyright
      if (!isMobile && window.scrollY > 30) {
        document.getElementById('copyright').style.visibility = 'visible';
      } else {
        document.getElementById('copyright').style.visibility = 'hidden';
      }

      // menu
      let basePx = 180;
      if (isMobile || window.scrollY > window.innerHeight - basePx) {
        document.getElementById('menu_news').style.visibility = 'visible';
      } else {
        document.getElementById('menu_news').style.visibility = 'hidden';
      }
      if (isMobile || window.scrollY > window.innerHeight - basePx - 50) {
        document.getElementById('menu_project').style.visibility = 'visible';
      } else {
        document.getElementById('menu_project').style.visibility = 'hidden';
      }
      if (isMobile || window.scrollY > window.innerHeight - basePx - 100) {
        document.getElementById('menu_profile').style.visibility = 'visible';
      } else {
        document.getElementById('menu_profile').style.visibility = 'hidden';
      }
      if (isMobile || window.scrollY > window.innerHeight - basePx - 150) {
        document.getElementById('menu_contact').style.visibility = 'visible';
      } else {
        document.getElementById('menu_contact').style.visibility = 'hidden';
      }


    },
    scrollForTop() {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    },
    scrollForContents() {
      let isMobile = window.innerWidth <= 768;
      let height = document.getElementById('contents').getBoundingClientRect().top + window.pageYOffset - (isMobile ? 70 : 0);
      window.scroll({top: height, left: 0, behavior: 'smooth'});
    },
    setFillHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
#logo1, #logo2, #logo_bottom, #copyright, .scroll {
  user-select: none;
}
#logo1 {
  cursor: pointer;
}
#contents {
  margin-top: 60px;
}
.fade-in {
  animation: fadeIn 5s ease 1 normal;
}
.fade-in-out {
  animation: fadeInVertical 3s ease 1 normal, flicker 3s ease-in-out 4s infinite alternate;
}

.logo_left_top1 {
  position: fixed;
  z-index: 200;
  top: 50px;
  left: 50px;
  width: 113px;
  height: 30px;
  background-size: cover;
  background-image: url(../assets/img/muku_black.png);
}
.logo_left_top1 .white {
  z-index: 201;
  background-image: url(../assets/img/muku_white.png);
  background-size: cover;
}
.logo_left_top2 {
  position: fixed;
  z-index: 200;
  top: 90px;
  left: 50px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: initial;
  font-size: 12pt;
}
.logo_left_top2.top {
  color: white;
}
.logo_left_bottom {
  position: fixed;
  z-index: 200;
  bottom: 50px;
  left: 50px;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-image: url(../assets/img/muku_mark_black.png);
}
.logo_left_bottom2, .copyright2 {
  display: none;
}
@media (max-width: 768px){
  .logo_left_top1, .logo_left_top2, .logo_left_bottom, .logo_left_bottom2 {
    position: absolute;
    left: 4%;
  }
  .logo_left_top1 {
    top: 4%;
  }
  .logo_left_top2 {
    top: calc(4% + 40px);
  }
  .logo_left_bottom2 {
    display: block;
    width: 100px;
    height: 100px;
    bottom: 50px;
    background-size: cover;
    background-image: url(../assets/img/muku_mark_black.png);
  }
  .copyright2 {
    position: absolute;
    display: block;
    bottom: 0px;
    left: 4%;
    width: 200px;
    height: 30px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: initial;
    font-size: 10px;
    line-height: 10px;
  }
}
.logo_left_bottom .white {
  z-index: 201;
  background-image: url(../assets/img/muku_mark_white.png);
  background-size: cover;
}
.copyright {
  position: fixed;
  z-index: 200;
  bottom: 0px;
  left: 50px;
  width: 200px;
  height: 30px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: initial;
  font-size: 10px;
  line-height: 10px;
}
.copyright.top {
  visibility: hidden;
}
.scroll {
  position: absolute;
  z-index: 200;
  height: 50px;
  font-size: 26px;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  color: white;
  cursor: pointer;
}
.scroll img{
  vertical-align: middle;
}

.spacer {
  height: 50px;
}
/* override container max-width */
.container {
  max-width: 100%;
}

/* --- 全体のBOX定義 ---------------------------------------- */
.box {
  border-radius: 0px;
  position   : relative;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin     : auto;
  overflow   : hidden;
}

/* --- 背景の指定 ------------------------------------------- */
.box .bgImg {
  border-radius: 0px;
  position   : absolute;
  top        : 0;
  left       : 0;
  bottom     : 0;
  right      : 0;
  clip-path: inset(0px 0px 0px 0px);
  z-index: 2;
  animation  : bgAnime 15s linear 0s infinite;   /* 3画像 × 各5s = 15s */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* --- 段差で背景画像のアニメーションを実行 ----------------- */
.box .src1 {
  animation-delay  : 5s;
}
.box .src2 {
  animation-delay  : 10s;
}

@keyframes bgAnime {
  0% {
    clip-path: inset(0px 0px 0px 0px);
    z-index: 2;
  }
  28.3% {
    clip-path: inset(0px 0px 0px 0px);
    z-index: 2;
  }
  33.3% {
    clip-path: inset(0px 100vw 0px 0px);
    z-index: 2;
  }
  39.5% {
    clip-path: inset(0px 100vw 0px 0px);
    z-index: 0
  }
 66.7% {
    clip-path: inset(0px 0px 0px 0px);
    z-index: 0;
  }
 66.8% {
    clip-path: inset(0px 0px 0px 0px);
    z-index: 1;
  }
 100% {
    clip-path: inset(0px 0px 0px 0px);
    z-index: 1;
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  20% {opacity: 0;
  left: 100px;}
  100% {opacity: 1;}
}
@keyframes fadeInVertical {
  0% {opacity: 0;}
  20% {opacity: 0;
  bottom: 100px;}
  100% {opacity: 1;}
}
@keyframes flicker {
  0% {opacity: 1;}
  100% {opacity: 0.2;}
}
</style>