import { createRouter, createWebHistory } from 'vue-router'
import Top from '@/page/Top.vue'
import NewsList from '@/page/NewsList.vue'
import ProjectList from '@/page/ProjectList.vue'
import ProjectDetail from '@/page/ProjectDetail.vue'
import Profile from '@/page/Profile.vue'
import Contact from '@/page/Contact.vue'
import { trackRouter } from "vue-gtag-next";

const routes = [
    {
      path: '/',
      name: 'Top',
      component: Top
    },
    {
      path: '/news',
      name: 'News',
      component: NewsList
    },
    {
      path: '/project',
      name: 'Project',
      component: ProjectList
    },
    {
      path: '/project/:url',
      name: 'ProjectDetail',
      component: ProjectDetail
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    }
  ]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes
})

trackRouter(router)

export default router